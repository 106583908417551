//import map functions
import { maps, addmarker, styles } from './map.js';

import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

jQuery(document).ready(function($) {
	
	//menu
	$('body').on('click','.mobileMenu',function(){
		$('.menu .content').show();
		$('#page').toggleClass('mobileOpen');
		$('.menu .content').toggleClass('open');
		$('.menuContainer').toggleClass('active');
		$('.menuContainer .menu').toggleClass('show');

		$(this).toggleClass('open');
	});


	$('body').on('click', '.menuContainer .hasChild>a', function(event){
		event.preventDefault();
		
		$(this).parent().find('.dropdownMenu').slideToggle()
	});




		//magnify and shrink accessibility

	//maginfy text
	var increment = 0;
	$('body').on('click','.fontIncrease',function(){
		increment++;

		$('button:not(.button),p,li,h1,h2,h3,h4,h5,.btn,input[type="submit"],a, button .button,.pill span,.next,.prev,.minutes .end,.minutes .beginning,.details .station'  ).each(function(){
			var curr_font_size = $(this).css('font-size');
			var curr_line_height = $(this).css('line-height');
			
			var new_font_size = parseFloat(curr_font_size) * 1.255;
			var new_line_height = parseFloat(curr_line_height) * 1.255;

			$(this).css({'font-size': new_font_size , 'line-height': new_line_height+'px'});
		});

		if(increment == 1){
			//$('section .headerSection .content').css({'max-width':'100%' , 'padding-right': '50px'})
			$('#shrink').addClass('fontDecrease');
		}

		if(increment == 2){
			$('#magnify').removeClass('fontIncrease');
		}

		if(increment > 0){
			$('#shrink p').html('-');
		}


	});

	//shrink text
	$('body').on('click','.fontDecrease',function(){

		$('button:not(.button),p,li,h1,h2,h3,h4,h5,.btn,input[type="submit"],a,button .button,.pill span,.next,.prev,.minutes .end,.minutes .beginning,.details .station' ).each(function(){
			var curr_font_size = $(this).css('font-size');
			var curr_line_height = $(this).css('line-height');
			
			var new_font_size = parseFloat(curr_font_size) / 1.255;
			var new_line_height = parseFloat(curr_line_height) / 1.255;

			$(this).css({'font-size': new_font_size, 'line-height': new_line_height+'px'});
		});


		increment--;

		
		if(increment == 0){
			$('#shrink').removeClass('fontDecrease');
		}

		if(increment < 2){
			$('#magnify').addClass('fontIncrease');
		}
	});




	//SWIPERS

	const eventsSlider = document.querySelectorAll('.eventsSlider-wrapper');
	var i = 0;

	for( i=0; i< eventsSlider.length; i++ ) {

		var first = eventsSlider[i].nextElementSibling;
		var second = first.nextElementSibling;
		var third = second.nextElementSibling;

		eventsSlider[i].classList.add('eventsSlider-wrapper-' + i); //slider
		first.classList.add('swiper-pagination-' + i); //pagination
		second.classList.add('eventsSlider-button-next-' + i); //next
		third.classList.add('eventsSlider-button-prev-' + i); //previous

		var slider = new Swiper('.eventsSlider-wrapper-' + i, {
			speed: 1000,
			slidesPerView: 3,
			spaceBetween: 40,
			direction: 'horizontal',
			loop: true,
			autoplay: false,
			navigation: {
				nextEl: '.eventsSlider-button-next-' + i,
				prevEl: '.eventsSlider-button-prev-' + i,
			},
			breakpoints: {
				991: {
					slidesPerView: 3,
					spaceBetween: 30
				},
				768: {
				  slidesPerView: 2,
				  spaceBetween: 30
				},
				0: {
				  slidesPerView: 1,
				  spaceBetween: 0
				}
			}
		});

	}


	const newsSlider = document.querySelectorAll('.newsSlider-wrapper');
	var i = 0;

	for( i=0; i< newsSlider.length; i++ ) {

		var first = newsSlider[i].nextElementSibling;
		var second = first.nextElementSibling;
		var third = second.nextElementSibling;

		newsSlider[i].classList.add('newsSlider-wrapper-' + i); //slider
		first.classList.add('swiper-pagination-' + i); //pagination
		second.classList.add('newsSlider-button-next-' + i); //next
		third.classList.add('newsSlider-button-prev-' + i); //previous

		var slider = new Swiper('.newsSlider-wrapper-' + i, {
			speed: 1000,
			slidesPerView: 1,
			spaceBetween: 40,
			direction: 'horizontal',
			loop: true,
			autoplay: false,
			navigation: {
				nextEl: '.newsSlider-button-next-' + i,
				prevEl: '.newsSlider-button-prev-' + i,
			}
		});

	}



	const twitterSlider = document.querySelectorAll('.twitterSlider-wrapper');
	var i = 0;

	for( i=0; i< twitterSlider.length; i++ ) {

		var first = twitterSlider[i].nextElementSibling;
		var second = first.nextElementSibling;
		var third = second.nextElementSibling;

		twitterSlider[i].classList.add('twitterSlider-wrapper-' + i); //slider
		first.classList.add('swiper-pagination-' + i); //pagination
		second.classList.add('twitterSlider-button-next-' + i); //next
		third.classList.add('twitterSlider-button-prev-' + i); //previous

		var slider = new Swiper('.twitterSlider-wrapper-' + i, {
			speed: 1000,
			slidesPerView: 3,
			spaceBetween: 75,
			direction: 'horizontal',
			loop: true,
			autoplay: false,
			// navigation: {
			// 	nextEl: '.twitterSlider-button-next-' + i,
			// 	prevEl: '.twitterSlider-button-prev-' + i,
			// },
			breakpoints: {
				991: {
					slidesPerView: 3,
					spaceBetween: 75
				},
				768: {
				  slidesPerView: 2,
				  spaceBetween: 30
				},
				0: {
				  slidesPerView: 1,
				  spaceBetween: 0
				}
			}
		});

	}



	const headerSwiper = new Swiper("#headerSwiper.slider", {
        slidesPerView: 3,
		autoplay: false,
		loop: true,
		speed: 1000,
		spaceBetween: 30,
		pagination: {
			el: '.headerSwiper-pagination',
			type: 'bullets',
			clickable: true,
		},
		breakpoints: {
			991: {
				slidesPerView: 3,
				spaceBetween: 30
			},
			768: {
			  slidesPerView: 2,
			  spaceBetween: 30
			},
			0: {
			  slidesPerView: 1,
			  spaceBetween: 0
			}
		}
    });




	// $('body').on('click', '#stationmap .stationName', function(event){
	// 	event.preventDefault();
	// 	var station = $(this).attr("data-station");
	// 	window.location.href = '/stations/'+station+'';
	// });




	//HEADING
	let value = window.scrollY;
	if(window.scrollY!=0){
		// $("#textSVG").removeClass("desktop");
		$("#RobinHoodLine_RGB").removeClass("desktop");
		$('.topNav').removeClass('desktop')
	}

	window.addEventListener('scroll', function(){
		value = window.scrollY;
		//heading
		if(value > 0 && $("#textSVG").hasClass("desktop")){
			// $("#textSVG").removeClass("desktop");
			$("#RobinHoodLine_RGB").removeClass("desktop");	
			$('.topNav').removeClass('desktop')
			$('.accessibilityContainer').slideUp();
		}else if(value == 0){
			// $("#textSVG").addClass("desktop");
			$("#RobinHoodLine_RGB").addClass("desktop");
			$('.topNav').addClass('desktop')
			$('.accessibilityContainer').slideDown();
		}
	})



	//PARRALLAX

	if(document.getElementById("homepageBanner") !== null){

		let landscapeFront = document.getElementById("landscapeFront");
		let landscapeBack = document.getElementById("landscapeBack");
		let sun = document.getElementById("sun");
		let rightCloud = document.getElementById("rightCloud");
		let middleCloud = document.getElementById("middleCloud");
		let leftCloud = document.getElementById("leftCloud");
		let headerText = document.getElementById("headerText");
		let introHeading = document.getElementById("introHeading");
		let introHeadingParagraph = introHeading.querySelector("p");
		// let filler = document.getElementById("filler");
		// let overlay = document.getElementById("overlay");
		let moveSpeed = 0.1;
		let valueScrolled = 0;
		

		leftCloud.style.left = '-' + value * 0.25 + 'px';
		rightCloud.style.right = '-' + value * 0.25 + 'px';
		leftCloud.style.top = value * 0 + 'px';
		rightCloud.style.top = value * 0 + 'px';
		middleCloud.style.top = value * 0 + 'px';
		

		//introHeading.style.transform = "translate3d(0px,"+value * 0+"px,0px)";
		introHeadingParagraph.style.transform = "translate3d(0px,"+value * 0+"px,0px)";
		headerText.style.transform =  "translate3d(0px,"+value * 0+"px,0px)";
		landscapeBack.style.transform = "translate3d(0px,"+value * 0.6+"px,0px)";
		landscapeFront.style.transform = "translate3d(0px,"+value * 0.45+"px,0px)";

		sun.style.transform =  "translate3d(0px,"+value +"px,0px)";

		window.addEventListener('scroll', function(){
			value = window.scrollY;
			
			// stay as they are from the top
			leftCloud.style.left = '-' + value * 0.55 + 'px';
			rightCloud.style.right = '-' + value * 0.5 + 'px';
			leftCloud.style.top = value * 0 + 'px';
			rightCloud.style.top = value * 0 + 'px';
			middleCloud.style.top = value * 0 + 'px';

			//introHeading.style.transform = "translate3d(0px,"+value * -0.65+"px,0px)";
			if (value * moveSpeed <= 100) introHeadingParagraph.style.transform = "translate3d(0px,"+value * moveSpeed+"px,0px)";
			headerText.style.transform =  "translate3d(0px,"+value * -0.7+"px,0px)";
			landscapeBack.style.transform = "translate3d(0px,"+value * -0.45+"px,0px)";
			landscapeFront.style.transform = "translate3d(0px,"+value * -0.6+"px,0px)";

			sun.style.transform =  "translate3d(0px,"+value*-0.3 +"px,0px)";

		});
	}




	//trainline hover function
	$('body').on('mouseover', '.stationHover', function(event){
		event.preventDefault();
		$(this).parent().addClass('hovering')
	});

	$('body').on('mouseleave', '.stationHover', function(event){
		event.preventDefault();
		$(this).parent().removeClass('hovering')
	});





	//STICKY MENU
	// window.addEventListener('scroll', function(){
		
	// })


	$('body').on('click', '.filterButton', function(event){
		event.preventDefault();
		$('#nav-icon3').toggleClass('open');
		$('.filterContainer .filter').slideToggle()
	});


	//MAP Functions
	var lat = $('#map').attr("data-lat");
	var lng = $('#map').attr("data-lng");
	var title = $('#map').attr("data-title");
	var icon = $('#map').attr("data-icon");
	var link = $('#map').attr("data-link");
	var route = $('#map').attr("data-route");
	var locations = [];


	var station = [title,lat,lng,icon,link,''];
	locations.push( station );

	//lat and long  of uluru to define center map on init
	var uluru = { lat: parseFloat(lat), lng: parseFloat(lng) };
	//get json from this file name from adjacent json folder
    // var route = 'route2';
	var route = route;
	//map container
	var div = document.getElementById("map");
	//marker locations on map


	var marks = jQuery('main').find('.marker');
	
	marks.each(function() {
		var element = jQuery(this);
		var array = [element.data('title'),element.data('lat'),element.data('lng'),element.data('icon'),element.data('link'),element.data('image'),element.data('target')]
		locations.push( array );
	})


	if(jQuery('div#map').length) {
		//init map
		maps();
		initMap(uluru,styles,div);
		addmarker(route, uluru ,styles, locations, div)
	}

	//click to add locations to map and path
	$('body').on('click', '#btnaddmarker', function(event){
		//locations for marker
		addmarker(route, uluru ,styles, locations, div)
	});

});

